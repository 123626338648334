<template>
  <div>
    <b-modal id="addteachermodal" modal-class="modal-right-pane">
      <template v-slot:modal-header="{ close }">
        <h2>{{ $t('add_staff') }}</h2>
        <a class="pointer position-relative modal-close" @click="close()">
          <CloseSvg/>
        </a>
      </template>
      <div>
        <h3>{{ $t('general_information') }}</h3>
        <div>
          <b-row>
            <b-col lg="6">
              <InputGroup label="Email" class="default" type="email" v-model="form.email" :readonly="user"/>
            </b-col>
            <b-col lg="6">
              <InputGroup :label="$t('name')" class="default" type="text" v-model="form.name"/>
            </b-col>
            <b-col lg="6">
              <PhoneGroup :label="$t('phone')" class="default" type="text" v-model="form.phone"/>
            </b-col>
          </b-row>
          <div v-if="user && user.id">
            <a class="small text-primary pointer" v-if="!newPass" @click="resetPassword">{{ $t('reset_password') }}</a>
            <div v-if="newPass">{{ $t('new_password') }}: <b>{{ newPass }}</b></div>
          </div>
        </div>
        <!--            <div v-if="!user">-->
        <!--                <h3>{{ $t('price_per_lesson') }} ({{ $store.state.user.currency }})</h3>-->
        <!--                <InputGroup :label="$t('price')" type="number" class="default" v-model="form.price"/>-->
        <!--            </div>-->
        <h3>{{ $t('photo') }}</h3>
        <div>
          <p class="form-note modal-pl">{{ $t('image_requirements') }}</p>
          <FileGroup accept="image/png,image/jpg,image/jpeg"
                     v-model="image"
                     fid="teacherphoto"
                     :label="$t('add_photo')"/>
        </div>
        <div v-if="form.primary_role_id == 1">
          <div class="d-flex align-items-center">
            <h3 class="d-flex align-items-center">
              {{ $t('price_policy') }}
              <HelpSvg class="help ml-1"  :hover="showTooltip = true" id="p_policy"/>
            </h3>
            <b-popover v-if="showTooltip" target="p_policy" triggers="hover" placement="top" custom-class="gray-popover">
              {{ this.$t('price_policy_info') }}
            </b-popover>
          </div>
          <div v-if="priceLoaded">
            <SelectGroup class="default"
                         :key="'pp-pol-'+ppkey"
                         v-model="form.pricepolicy_id"
                         :options="pricepolicies"
                         title="title_price"/>
          </div>
          <a class="text-themed pointer" @click="addPricePolicy" v-if="$store.state.user && $store.state.user.primary_role_id != 5">
            <PlusSvg class="svg-themed svg-icon-xs d-inline-block align-middle mr-2"/>
            <div class="d-inline-block align-middle">{{ $t('create_price_policy') }}</div>
          </a>
        </div>
        <h3>{{ $t('role') }}</h3>
        <SelectGroup class="default"
                     :key="'pp-as-'+ppkey"
                     v-model="form.primary_role_id"
                     :options="roles"
                     :translate="true"/>
        <h3>{{ $t('timezone') }}</h3>
        <SelectGroup class="default" v-model="form.timezone" :options="timezones" title="title"/>
        <h3>{{ $t('rights') }}</h3>
        <CheckboxGroup :label="$t('can_admin_payments')" v-model="form.can_admin_payments" fid="adminpay"/>
      </div>
      <template v-slot:modal-footer>
        <b-button v-if="user" class="btn-themed btn-white" @click="destroy" :disabled="loading"><TrashSvg/></b-button>
        <b-button class="btn-themed" @click="save" :disabled="loading">{{ $t('save') }}</b-button>
      </template>
    </b-modal>
    <AddPricePolicyModal @reload="getPricePolicies" :policy="null"></AddPricePolicyModal>
  </div>
</template>

<script>
import CloseSvg from '@/assets/svg/close.svg'
import CheckboxGroup from "@/views/parts/general/form/CheckboxGroup"
import InputGroup from "@/views/parts/general/form/InputGroup"
import SelectGroup from "@/views/parts/general/form/SelectGroup"
import FileGroup from "@/views/parts/general/form/FileGroup"
import PhoneGroup from "@/views/parts/general/form/PhoneGroup"
import { TeachersService, PricePoliciesService } from '@/services/api.service'
import TrashSvg from '@/assets/svg/trash2.svg'
import LangHelper from "@/services/lang.helper"
import moment from 'moment-timezone'
import PlusSvg from '@/assets/svg/plus.svg'
import HelpSvg from "@/assets/svg/help.svg"
import AddPricePolicyModal from '@/views/pages/instructor/settings/AddPricePolicyModal'

export default {
  components: {
    CloseSvg,
    InputGroup,
    FileGroup,
    PhoneGroup,
    TrashSvg,
    SelectGroup,
    CheckboxGroup,
    AddPricePolicyModal,
    HelpSvg,
    PlusSvg
  },
  data() {
    return {
      form: {
        email: "",
        name: "",
        phone: "",
        can_admin_payments: false,
        timezone: moment.tz.guess(),
        price: "",
        pricepolicy_id: null,
        level: null,
      },
      image: null,
      timezones: [],
      pricepolicies: [],
      priceLoaded: false,
      levels: [],
      ppkey: 0,
      newPass: null,
      loading: false,
      roles: [
        { id: '1', title: 'teacher' },
        { id: '5', title: 'manager' },
        { id: '6', title: 'methodist' },
      ]
    }
  },
  props: {
    user: { type: Object, default: null }
  },
  mounted() {
    this.timezones = LangHelper.timezonesList()
    this.$root.$on('bv::modal::show', (bvEvent, modalId) => {
      if (modalId === "addteachermodal") {
        if(this.$store.state.user.primary_role_id == 5) {
          this.roles = [{id: '1', title: 'teacher'} ]
        }
        this.loading = false
        this.newPass = null
        if(this.user) {
          console.log(this.user)
          this.form = {
            email: this.user.email,
            name: this.user.name,
            phone: this.user.phone,
            pricepolicy_id: this.user.pricepolicy_id,
            primary_role_id: this.user.primary_role_id,
            can_admin_payments: this.user.can_admin_payments,
            timezone: this.user.timezone,
          }
        } else {
          this.form = {
            id: "",
            name: "",
            pricepolicy_id: null,
            timezone: moment.tz.guess(),
            price: "",
          }
        }
      }
    })
    this.getPricePolicies()
  },
  methods: {
    save() {
      let formData = new FormData()
      if(this.image) {
        formData.append('image', this.image)
      }
      formData = this.appendFormdata(formData, this.form)
      this.loading = true

      if(this.user) {
        TeachersService.update(this.user.id, formData).then(res => {
          let r = this.apiResponse(res)
          if(r.success) {
            this.$bvModal.hide('addteachermodal')
            this.$toast(this, this.$t('teacher_changed'))
            this.$emit('refresh')
          } else {
            this.$error(r.data)
          }
          this.loading = false
        }).catch(err => {
          console.log(err)
          this.loading = false
        })
      } else {
        TeachersService.add(formData).then(res => {
          let r = this.apiResponse(res)
          if(r.success) {
            if(r.data.password) {
              this.$store.commit('setUserInvitationModalData', {
                password: r.data.password,
                emailSent: true,
                login: this.form.email
              })
            }
            this.$bvModal.show('invitationsentmodal')
            this.$bvModal.hide('addteachermodal')
            this.$emit('refresh')
          } else {
            this.$error(r.data)
          }
          this.loading = false
        }).catch(err => {
          console.log(err)
          this.loading = false
        })
      }
    },
    destroy() {
      this.$confirm(this.$t('are_you_sure_you_want_to_delete')).then(() => {
        this.loading = true
        TeachersService.delete(this.user.id).then(() => {
          this.$bvModal.hide('addteachermodal')
          this.$success(this.$t('teacher_deleted'))
          this.$emit('refresh')
          this.loading = false
        }).catch(() => {
          this.loading = false
        })
      })
    },
    addPricePolicy() {
      this.$bvModal.show('addpricemodal')
    },
    getPricePolicies(policy = null) {
      PricePoliciesService.get(false).then(res => {
        this.pricepolicies = res.data.data
        if(policy) {
          this.$set(this.form, 'pricepolicy_id', policy.id)
          this.ppkey = policy.id
        }
      }).then(() => {
        this.priceLoaded = true
      }).catch(() => {
        this.priceLoaded = false
      })
    },
    async resetPassword() {
      if(!this.user) return
      let res = await TeachersService.resetPassword(this.user.id)
      this.newPass = res.data.data
    },
  },
  computed: {}
}
</script>

<style scoped lang="scss">

</style>


