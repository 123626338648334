<template>
  <div class="user-teaser" :class="'user-'+teacher.student_status">
    <b-row class="align-items-center">
      <b-col lg="auto" cols="auto" class="opacity-changed">
        <LetterIcon :obj="teacher" :title="teacher.name" class="student-icon"/>
      </b-col>
      <b-col col>
        <div class="pl-3  opacity-changed">
          <h3>{{ teacher.name }}</h3>
        </div>
      </b-col>
      <b-col lg="2" cols="12" class="opacity-changed mt-md-0 mt-3">
        <div v-if="teacher.primary_role_id == 1">
        <div v-if="teacher.student_status != 'active'">
          {{ $t(teacher.student_status) }}
        </div>
        <div v-else>
          <div>
            {{ teacher.students_count }} {{ $t('of_students') }}
          </div>
        </div>
          <!--                    <div v-if="!teacher.student_lessons || student.student_lessons.length < 1">-</div>-->
        </div>
      </b-col>
      <b-col lg="2" cols="6" class="opacity-changed">
        {{ $t(teacher.roleTitle) }}
      </b-col>
      <b-col lg="auto" cols="6">
        <div class="text-right">
          <b-dropdown right no-caret menu-class="noscrollbar" variant="link" class="d-inline-block pointer">
            <template v-slot:button-content>
              <MoreSvg class="d-inline-block"/>
            </template>
            <b-dropdown-item v-if="teacher.student_status == 'active'" @click="edit">{{ $t('edit') }}</b-dropdown-item>
            <b-dropdown-item v-if="teacher.student_status == 'active'" @click="passwordReset">{{ $t('reset_password') }}</b-dropdown-item>
            <!--                        <b-dropdown-item v-if="teacher.student_status == 'active'" @click="addPayment">{{ $t('add_payment') }}</b-dropdown-item>-->
            <!--                        <b-dropdown-item v-if="teacher.student_status == 'active'" @click="pauseStudent">{{ $t('pause_student') }}</b-dropdown-item>-->
            <!--                        <b-dropdown-item v-if="teacher.student_status == 'paused'" @click="activateStudent">{{ $t('activate_student') }}</b-dropdown-item>-->
            <!--                        <b-dropdown-item v-if="teacher.student_status == 'paused'" @click="archiveStudent">{{ $t('archive_student') }}</b-dropdown-item>-->
            <!--                        <b-dropdown-item @click="addNote">{{ $t('add_note') }}</b-dropdown-item>-->
            <!--                        <b-dropdown-item v-if="teacher.student_status == 'active'" :to="{ name: 'Schedule', query: { students: teacher.id }}">{{ $t('schedule') }}</b-dropdown-item>-->
          </b-dropdown>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import MoreSvg from '@/assets/svg/more.svg'
// import WarningSvg from '@/assets/svg/warning.svg'
import LetterIcon from '@/views/parts/general/LetterIcon'
import { StudentsService } from '@/services/api.service'
import {TeachersService} from "../../services/api.service"

export default {
  components: {
    MoreSvg,
    // WarningSvg,
    LetterIcon
  },
  data() {
    return {

    }
  },
  props: {
    teacher: { type: Object }
  },
  methods: {
    edit() {
      this.$emit('edit', this.teacher)
    },
    pauseStudent() {
      this.changeStudentStatus('paused')
    },
    activateStudent() {
      this.changeStudentStatus('active')
    },
    archiveStudent() {
      this.changeStudentStatus('archived')
    },
    changeStudentStatus(val) {
      StudentsService.updateStatus(this.teacher.id, val).then(() => {
        this.$emit('refresh')
      })
    },
    async passwordReset() {
      if(!this.teacher) return
      let res = await TeachersService.resetPassword(this.teacher.id)
      let newPass = res.data.data
      if(newPass) {
        this.$store.commit('setUserInvitationModalData', {
          password: newPass,
          emailSent: false,
          login: this.teacher.email
        })
        this.$bvModal.show('invitationsentmodal')
      }
    },
    // addNote() {
    //     this.$input(this.$t('student_note'),this.$t('note'), this.student.note).then((val) => {
    //         StudentsService.updateNote(this.student.id, val)
    //     })
    // },
    addPayment() {
      this.$emit('addpay')
    }
  },
  watch: {

  },
  mounted() {

  },

}
</script>

<style scoped lang="scss">

</style>






