<template>
  <section id="teachers">
    <b-row class="align-items-center">
      <b-col col md="9">
        <h1 id="page-title">
          {{ $t('staff') }}
        </h1>
      </b-col>
      <b-col cols="auto" md="3">
        <SelectGroup class="default"
                     :floatinglabel="true"
                     v-model="filter"
                     :hasNullOption="false"
                     :options="roles"
                     :label="$t('filter')"/>
      </b-col>
    </b-row>
    <b-row class="row-wide">
      <b-col lg="auto" class="mb-lg-0 mb-3">
        <div class="left-sideblock">
          <button class="btn-themed w-100 mb-24" @click="addTeacher">{{ $t('add_staff') }}</button>
        </div>
      </b-col>
      <b-col lg>
        <div class="spacecard">
          <b-row class="align-items-center mb-3">
            <b-col>
              <h2 class="mb-0">{{ $t('all_staff') }}</h2>
            </b-col>
            <b-col cols="auto">
              <span class="text-muted small" v-if="paginator">{{ $t('staff') }}: {{ paginator.total }}</span>
            </b-col>
          </b-row>
          <StudentSearch :showwithoutselection="true"
                         :onlyactive="false"
                         initMode="teachers"
                         :placeholder="$t('search')"
                         :filter="filter"
                         @initialfindings="foundThroughSearch"/>
          <TeacherTeaser v-for="(t, sind) in teachers"
                         @edit="editTeacher"
                         @refresh="refresh"
                         @addpay="addpay(t)"
                         :teacher="t"
                         :key="'student-'+sind"/>
        </div>
        <Paginator :paginator="paginator"/>
      </b-col>
    </b-row>
    <AddTeacherModal @refresh="refresh"
                     v-if="addteachermodal"
                     :user="addingnewteacher ? null : selectedTeacher"/>
    <InvitationSentModal user="teacher"/>
    <AddPaymentModal
        :passedstudents="[]"
        @refresh="refresh"/>
  </section>
</template>

<script>
import StudentSearch from '@/views/parts/class/StudentSearch'
import AddTeacherModal from '@/views/pages/school/teachers/AddTeacherModal'
import AddPaymentModal from "@/views/pages/instructor/payments/AddPaymentModal"
import TeacherTeaser from '@/views/teasers/TeacherTeaser'
import { TeachersService } from '@/services/api.service'
import EventBus from '@/services/event-bus'
import Paginator from "@/views/parts/general/Paginator"
import InvitationSentModal from "@/views/pages/instructor/students/InvitationSentModal"
import SelectGroup from "@/views/parts/general/form/SelectGroup"

export default {
  components: {
    InvitationSentModal,
    AddPaymentModal,
    Paginator,
    StudentSearch,
    TeacherTeaser,
    AddTeacherModal,
    SelectGroup
  },
  data() {
    return {
      paginator: null,
      teachers: [],
      allteachers: [],
      addteachermodal: false,
      addingnewteacher: false,
      selectedTeacher: null,
      filter: 'all',
      roles: [
        { id: 'all', title: 'all' },
        { id: '1', title: 'teachers' },
        { id: '5', title: 'managers' },
        { id: '6', title: 'methodists' },
      ],
      page: this.$route.query.page ? this.$route.query.page : 1,
    }
  },
  mounted() {
    this.refresh()
    EventBus.$emit('breadcrumbs',[
      { to: '/school/teachers', title: this.$t('teachers') },
    ])
  },
  methods: {
    refresh() {
      TeachersService.get({ role: this.filter }, this.page).then(res => {
        this.allteachers = res.data.data.data
        this.teachers = this.allteachers
        this.paginator = res.data.data
      }).catch(err => {
        console.log(err)
      })
    },
    selectAll() {
      this.teachers = this.allteachers
    },
    foundThroughSearch(teachers) {
      if(teachers) {
        this.teachers = teachers
      } else {
        this.teachers = this.allteachers
      }
    },
    addTeacher() {
      this.addingnewteacher = true
      this.addteachermodal = true
      this.$nextTick(() => {
        this.$bvModal.show('addteachermodal')
      })
    },
    editTeacher(student) {
      this.selectedTeacher = student
      this.addingnewteacher = false
      this.addteachermodal = true
      this.$nextTick(() => {
        this.$bvModal.show('addteachermodal')
      })
    },
    addpay(student) {
      this.selectedTeacher = student
      this.$bvModal.show('addpaymentmodal')
    }
  },
  watch: {
    $route() {
      if(this.$route.query.page != this.page) {
        this.page = this.$route.query.page
        this.refresh()
      }
    },
    filter() {
      this.refresh()
    }
  },
  metaInfo() {
    return {
      title: this.$t('teachers'),
    }
  },
}
</script>

